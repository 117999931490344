import React from "react";
import {
  AppointmentInviteMeta,
  AppointmentInviteDate,
} from "components/appointments/AppointmentInvite";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { networkSelector } from "../../../../../app/javascript/selectors/environment";
import {
  acceptInvite,
  declineInvite,
} from "../../../../../app/javascript/actions/invites";
import classnames from "classnames";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BitkomCampaignInvite({
  invitable,
  detail,
  author,
  status,
  inviteStatus,
  hideMeta,
  id,
}) {
  const network = useSelector(networkSelector);
  const dispatch = useDispatch();

  const canOptRetroactively = get(network, [
    "config",
    "appointments",
    "opt_retroactively",
  ]);

  return (
    <div className="invite-container grid grid-cols-[1fr] sm:grid-cols-[30px_1fr_1fr] md:grid-cols-[30px_1.5fr_1fr] py-6 px-1.5 gap-2 sm:gap-3 md:gap-5">
      <FontAwesomeIcon
        className="fa-2xl hidden sm:block"
        icon={regular("calendar")}
      />
      <div className="invite-info">
        {!isEmpty(invitable) ? (
          <div>
            <div
              className={`appointment-name text-lg -mt-1 ${invitable.status}`}
            >
              <a href={`/appointments/${invitable.id}`}>{invitable.name}</a>
            </div>
            <div>
              <strong className="detail-name">{detail.name}</strong>
            </div>
            <AppointmentInviteDate date={invitable.date} />
            <p className="detail-description">{detail.description}</p>
          </div>
        ) : null}
        {author?.id ? (
          <div className="invite-author">
            {I18n.t("js.invites.invited_by") + " "}
            <a href={`/members/${author.id}`}>{author.name}</a>
          </div>
        ) : null}
      </div>
      <div
        // When total_attendees_enforced_and_exceeded is true, text is displayed instead of buttons.
        // This should be able to shrink, but the buttons not.
        className={classnames(
          "appointment-invite-interactions col-span-2 sm:col-span-1",
          {
            "shrink-0": !invitable.total_attendees_enforced_and_exceeded,
          },
        )}
      >
        {!hideMeta ? (
          <AppointmentInviteMeta
            inviteStatus={inviteStatus}
            status={status}
            decline={() => dispatch(declineInvite({ inviteId: id }))}
            accept={() => dispatch(acceptInvite({ inviteId: id }))}
            canOptRetroactively={canOptRetroactively}
            totalAttendeesEnforcedAndExceeded={
              invitable.total_attendees_enforced_and_exceeded
            }
          />
        ) : null}
      </div>
    </div>
  );
}
