import React from "react";
import BitkomCampaignInvite from "./BitkomCampaignInvite";
import { isEmpty } from "lodash";

const BitkomDetailParticipation = ({
  status,
  detail,
  campaign,
  invited_by,
  inviteStatus,
}) => (
  <BitkomCampaignInvite
    invitable={campaign}
    detail={detail}
    status={status}
    hideMeta={true}
    author={
      !isEmpty(invited_by) ? { id: invited_by.id, name: invited_by.name } : null
    }
    inviteStatus={inviteStatus}
  />
);

export default BitkomDetailParticipation;
